<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.FUELS.GPS_TITLE") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <button
            class="btn btn-warning font-weight-bolder ml-2"
            text
            @click="handleSyncGpsTankChargesYesterday"
          >
            {{ $t("MENU.ITEM.TANKS.SYNC") }}
          </button>

          <button
            class="btn btn-success font-weight-bolder ml-2"
            text
            @click="handleTransferGpsTankCharges"
          >
            {{ $t("MENU.ITEM.TANKS.CONFIRM_CHARGES") }}
          </button>
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend>
            <b-col class="pb-0" :md="4">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-to-input"
                  type="text"
                  name="date_from"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
                  :validateState="{}"
                  v-model="filters.dateFrom"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-from-input"
                  type="text"
                  name="date_to"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_TO' }"
                  :validateState="{}"
                  v-model="filters.dateTo"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
          </template>
        </SearchTools>
        <v-data-table
          v-if="dataFetched"
          :headers="tableHeaders"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="px-6 elevation-1"
        >
          <template v-slot:[`item.isSelected`]="{ item }">
            <v-checkbox
              v-model="item.isSelected"
              :input-value="item.isSelected"
            ></v-checkbox>
          </template>

          <template v-slot:[`item.transactionDateTime`]="{ item }">
            <FormDatepicker
              v-model="item.transactionDateTime"
              type="text"
              :i18n="{ placeholder: 'FORM_LABELS.DATE' }"
              :validateState="{}"
            ></FormDatepicker>
          </template>

          <template v-slot:[`item.gasTankStationId`]="{ item }">
            <FormSelect
              :options="gasStations"
              v-model="item.gasTankStationId"
              i18n="test"
              clearable="true"
              :validateState="
                item.isSelected && showErrors && !item.gasTankStationId
                  ? false
                  : null
              "
              appendToBody="true"
            />
            <div
              v-if="!item.gasTankStationId"
              class="d-flex justify-space-between mb-2"
            >
              {{ item.gpsGasTankStationId }}
              <router-link :to="'/gps-tanks/list'" target="_blank">
                <v-btn
                  width="20"
                  height="20"
                  fab
                  color="success"
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </router-link>
            </div>
          </template>

          <template v-slot:[`item.fuelable`]="{ item }">
            <FormSelect
              :options="vehicles"
              v-model="item.fuelable"
              i18n="test"
              clearable="true"
              :validateState="
                item.isSelected && showErrors && !item.fuelable ? false : null
              "
              appendToBody="true"
            />
            <div
              v-if="!item.fuelable"
              class="d-flex justify-space-between mb-2"
            >
              {{ item.gpsVehicleId }}
              <router-link :to="'/machines/list'" target="_blank">
                <v-btn
                  width="20"
                  height="20"
                  fab
                  color="success"
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </router-link>
            </div>
          </template>
          <template v-slot:[`item.staffId`]="{ item }">
            <FormSelect
              :options="staffList"
              v-model="item.staffId"
              i18n="test"
              clearable="true"
              appendToBody="true"
            />
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <FormInput
              v-model="item.quantity"
              type="number"
              :validateState="
                item.isSelected && showErrors && !item.quantity ? false : null
              "
            ></FormInput>
          </template>
          <template v-slot:[`item.mileage`]="{ item }">
            <FormInput
              v-model="item.mileage"
              type="number"
              :validateState="
                item.isSelected && showErrors && item.mileage === null
                  ? false
                  : null
              "
            ></FormInput>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <button
              v-if="checkIfUserHasPermission(['gps-tank-charges-delete'])"
              @click="onDelete(item.id)"
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" fab dark x-small v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("LABELS.DELETE") }}</span>
              </v-tooltip>
            </button>
            <template v-else>Няма права</template>
          </template>

          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
        <div class="card-toolbar d-flex justify-content-end mr-2 my-3 mr-5">
          <button
            class="btn btn-success font-weight-bolder ml-2"
            text
            @click="handleTransferGpsTankCharges"
          >
            {{ $t("MENU.ITEM.TANKS.CONFIRM_CHARGES") }}
          </button>
        </div>
      </v-card>
      <v-dialog
        class="send-modal"
        v-model="showTransferModal"
        max-width="600px"
        :persistent="loading"
      >
        <v-card :loading="loading" :disabled="loading">
          <v-card-text class="pb-0 pt-5">
            <v-container
              class="assignment-form-container"
              style="text-align: center"
            >
              <h2 v-if="!items.filter(item => item.isSelected).length">
                {{ $t("MENU.ITEM.TANKS.MARK_AT_LEAST_ONE_CHARGE") }}.
              </h2>
              <h2 v-else>
                {{
                  $t("MENU.ITEM.TANKS.CONFIRM_TRANSFER", {
                    value: items.filter(item => item.isSelected).length
                  })
                }}?
              </h2>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" text @click="showTransferModal = false">
              {{ $t("BUTTON.CLOSE") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="items.filter(item => item.isSelected).length"
              color="primary"
              @click="transferGpsTankCharges"
            >
              {{ $t("BUTTON.TRANSFER") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

import { FETCH_MACHINE_AND_TANK_OPTIONS } from "@/modules/tank-charges/store/tankcharges.module";

import SearchTools from "@/core/components/table/SearchTools.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";

import {
  FETCH_GPS_TANK_CHARGES,
  TRANSFER_GPS_TANK_CHARGES,
  DELETE_GPS_TANK_CHARGES,
  SYNC_YESTERDAY_GPS_TANK_CHARGES
} from "@/modules/gps-tank-charges/store/gpstankcharges.module";

import { FETCH_TANKS } from "@/modules/tanks/store/tanks.module";
import { FETCH_ALL_STAFF } from "@/modules/staff/store/staff.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  components: {
    SearchTools,
    FormDatepicker,
    FormSelect,
    FormInput
  },
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      items: [],
      totalItems: 100,
      searchQuery: "",
      gasStations: [],
      vehicles: [],
      staffList: [],
      showErrors: false,
      dataFetched: false,
      showTransferModal: false,
      options: {
        sortBy: ["transactionDateTime"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 30,
        totalItems: 100
      },
      filters: {
        dateFrom: null,
        dateTo: null,
        gasTankStationId: null
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        {
          text: this.$i18n.t("BUTTON.CHECKBOXES"),
          value: "isSelected",
          width: "5%"
        },
        {
          text: this.$i18n.t("FORM_LABELS.DATE"),
          value: "transactionDateTime",
          width: "15%"
        },
        {
          text: this.$i18n.t("MENU.ITEM.TANKS.FROM"),
          value: "gasTankStationId"
        },
        {
          text: this.$i18n.t("MENU.ITEM.EXP.FUELABLE_TYPE"),
          value: "fuelable"
        },
        {
          text: this.$i18n.t("FORM_PLACEHOLDERS.STAFF_NAME"),
          value: "staffId"
        },
        {
          text: this.$i18n.t("MENU.ITEM.FUELS.LITERS"),
          value: "quantity",
          width: "12%"
        },
        {
          text: this.$i18n.t("MENU.ITEM.FUELS.MILEAGES"),
          value: "mileage",
          width: "12%"
        },
        {
          align: "right",
          text: this.$i18n.t("MENU.ITEM.ACTIONS"),
          value: "actions",
          sortable: false,
          width: "5%"
        }
      ]
    };
  },
  watch: {
    items: {
      handler() {
        this.showErrors = false;
      },
      deep: true
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.FUELS.GPS_TITLE"),
        route: { name: "list-gps-tank-charges" }
      },
      { title: this.$i18n.t("MENU.ITEM.ASSETS.LIST") }
    ]);

    const [machinesAndTanks, staffData, tanksData] = await Promise.all([
      this.$store.dispatch(FETCH_MACHINE_AND_TANK_OPTIONS),
      this.$store.dispatch(FETCH_ALL_STAFF, "?active=1"),
      this.$store.dispatch(FETCH_TANKS)
    ]);

    this.vehicles = machinesAndTanks.data.map(v => ({
      id: {
        name: v.name,
        type: v.type,
        staffName: v.staffName,
        lastUsedStaffId: v.lastUsedStaffId,
        isMileage: v.isMileage,
        noMileage: v.noMileage,
        gpsTrackerNumber: v.gpsTrackerNumber,
        id: v.id
      },
      name: v.name
    }));

    this.staffList = staffData.data.items;
    this.gasStations = tanksData.data.items;

    this.dataFetched = true;
  },
  computed: {
    params() {
      return {
        ...this.options,
        ...this.filters,
        query: this.searchQuery
      };
    },
    tableHeaders() {
      return this.headers;
    }
  },
  methods: {
    doSearch() {
      if (this.options.page == 1) {
        this.getDataFromApi();
      } else {
        this.options.page = 1;
      }
    },
    doClear() {
      this.searchQuery = "";
      if (this.options.page == 1) {
        this.getDataFromApi();
      } else {
        this.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;

      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.$url.transformParams(params);
      vm.$store
        .dispatch(FETCH_GPS_TANK_CHARGES, apiParams)
        .then(data => {
          if (!data.data.items) {
            vm.items = [];
            vm.isPageLoading = false;
            return;
          }

          vm.$nextTick(function() {
            vm.items = data.data.items.map(item => {
              const station = vm.gasStations.find(
                station =>
                  station.gpsGasTankStationId === item.gpsGasTankStationId
              );
              if (station) {
                item.gasTankStationId = station.id;
              }

              const fuelable = item.gpsVehicleId
                ? vm.vehicles.find(
                    vehicle => vehicle.id.gpsTrackerNumber == item.gpsVehicleId
                  )
                : null;
              if (fuelable) {
                item.fuelable = fuelable.id;
                const staff = vm.staffList.find(
                  staff => staff.id == item.fuelable.lastUsedStaffId
                );
                if (staff) {
                  item.staffId = staff.id;
                }
              }
              return item;
            });

            vm.totalItems = data.data.totalItemsCount;
            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-gps-tank-charges" });
          }
        });
    },

    async transferGpsTankCharges() {
      this.showTransferModal = false;
      const selectedGpsTankCharges = this.items.filter(item => item.isSelected);
      const allFieldsFilled = selectedGpsTankCharges.every(
        item => item.fuelable && item.gasTankStationId && item.mileage !== null
      );

      if (!allFieldsFilled) {
        this.showErrors = true;
        this.$notify({
          group: "notify",
          type: "error",
          title: this.$t("ALERTS.ERROR"),
          text: this.$t("ALERTS.NO_DATA")
        });
        return;
      }

      if (!selectedGpsTankCharges.length) {
        return;
      }
      selectedGpsTankCharges.forEach(charge => {
        if (charge.staffId) {
          const staff = this.staffList.find(
            staff => staff.id === charge.staffId
          );
          charge.staffName = staff.name;
        }
      });

      await this.$store
        .dispatch(TRANSFER_GPS_TANK_CHARGES, selectedGpsTankCharges)
        .then(data => {
          this.$notify({
            group: "notify",
            type: "success",
            title: this.$t("ALERTS.SUCCESS"),
            text: data.message
          });
        })
        .catch(response => {
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
      this.getDataFromApi();
    },
    handleTransferGpsTankCharges() {
      this.showTransferModal = true;
    },
    handleSyncGpsTankChargesYesterday() {
      this.$dialog.confirm(this.$t("BUTTON.PLEASE_CONFIRM")).then(() => {
        this.$store
          .dispatch(SYNC_YESTERDAY_GPS_TANK_CHARGES)
          .then(response => {
            this.getDataFromApi();
            this.$notify({
              group: "notify",
              type: "success",
              title: this.$t("ALERTS.SUCCESS"),
              text: response.message
            });
          })
          .catch(response => {
            this.$notify({
              group: "notify",
              type: "error",
              title: this.$t("ALERTS.ERROR"),
              text: response.message
            });
          });
      });
    },
    onDelete(id) {
      this.$dialog.confirm(this.$t("BUTTON.PLEASE_CONFIRM")).then(() => {
        this.$store
          .dispatch(DELETE_GPS_TANK_CHARGES, id)
          .then(() => {
            this.getDataFromApi();
            this.$notify({
              group: "notify",
              type: "success",
              title: this.$t("ALERTS.SUCCESS"),
              text: this.$t("ALERTS.SUCCESSFUL_DELETE")
            });
          })
          .catch(response => {
            this.$notify({
              group: "notify",
              type: "error",
              title: this.$t("ALERTS.ERROR"),
              text: response.message
            });
          });
      });
    }
  }
};
</script>
